import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { doc, setDoc } from "firebase/firestore"; 
import { db } from '../../../src/config/firestore';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Edit = ({ employees, selectedEmployee, setEmployees, setIsEditing, getEmployees }) => {
  const id = selectedEmployee.id;

  const [firstName] = useState(selectedEmployee.firstName || ''); 
  const [videoGames, setVideoGames] = useState(selectedEmployee.videoGames || 0); 
  const [socks, setSocks] = useState(selectedEmployee.socks || 0); 
  const [connectball, setConnectBall] = useState(selectedEmployee.connectball || 0); 
  const [racing, setRacing] = useState(selectedEmployee.racing || 0);
  const [vr, setVr] = useState(selectedEmployee.vr || 0);
  const [f1, setF1] = useState(selectedEmployee.f1 || 0);
  const [hitBeans, setHitBeans] = useState(selectedEmployee.hitBeans || 0);
  const [clawMachine, setClawMachine] = useState(selectedEmployee.clawMachine || 0);
  const [hammer, setHammer] = useState(selectedEmployee.hammer || 0);
  const [justDance, setJustDance] = useState(selectedEmployee.justDance || 0);
  const [aliens, setAliens] = useState(selectedEmployee.aliens || 0);
  const [karaoke, setKaraoke] = useState(selectedEmployee.karaoke || 0);
  const [snowcross, setSnowCross] = useState(selectedEmployee.snowcross || 0);
  const [laserTag, setLaserTag] = useState(selectedEmployee.laserTag || 0);
  const [footballSnooker, setFootballSnooker] = useState(selectedEmployee.footballSnooker || 0);
  const [goKart, setGoKart] = useState(selectedEmployee.goKart || 0);
  const [basketball, setBasketball] = useState(selectedEmployee.basketball || 0);
  const [jumpIt, setJumpIt] = useState(selectedEmployee.jumpIt || 0);
  const [jurassicPark, setJurassicPark] = useState(selectedEmployee.jurassicPark || 0);
  const [totalSpent, setTotalSpent] = useState(selectedEmployee.totalSpent || 0);
  const [date, setDate] = useState(selectedEmployee.date || '');

  const [editHistory, setEditHistory] = useState([]);

  useEffect(() => {
    if (!date) {
      const currentDate = new Date().toISOString().split('T')[0];
      setDate(currentDate);
    }

    setTotalSpent(
      Number(videoGames) + Number(racing) + Number(vr) + Number(hammer) + Number(hitBeans) + Number(connectball) + Number(justDance) + Number(f1) +
      Number(clawMachine) + Number(aliens) + Number(goKart) + Number(laserTag) + Number(snowcross) + Number(socks) +
      Number(footballSnooker) + Number(jumpIt) + Number(basketball) + Number(jurassicPark)
    );
  }, [videoGames, racing, vr, hammer, socks, justDance, snowcross, goKart, hitBeans, jumpIt, f1, basketball, connectball, jurassicPark, clawMachine, aliens, laserTag, footballSnooker, date]);

  const formatDateTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedTime = `${hours % 12 || 12}:${minutes} ${ampm}`;
    const formattedDate = now.toISOString().split('T')[0];
    return { date: formattedDate, time: formattedTime };
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!firstName || !date) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'All fields are required, including the date.',
        showConfirmButton: true,
      });
    }

    const updatedEmployee = {
      id,
      firstName,
      videoGames,
      connectball,
      justDance,
      racing,
      vr,
      socks,
      snowcross,
      basketball,
      hitBeans,
      clawMachine,
      f1,
      hammer,
      karaoke,
      laserTag,
      footballSnooker,
      goKart,
      jumpIt,
      jurassicPark,
      aliens,
      totalSpent,
      date,
    };

    try {
      await setDoc(doc(db, "employees", id), { ...updatedEmployee });
      console.log("Document updated with ID: ", id);

      const { date: currentDate, time: currentTime } = formatDateTime();
      const editRecord = { time: currentTime, date: currentDate };
      setEditHistory(prev => [...prev, editRecord]);

      Swal.fire({
        icon: 'success',
        title: 'Updated!',
        text: `${updatedEmployee.firstName}'s data has been updated.`,
        showConfirmButton: false,
        timer: 1500,
      });

      setEmployees(employees);
      setIsEditing(false);
      getEmployees();

    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const incrementValue = (setter, incrementAmount) => {
    setter(prev => prev + incrementAmount);
  };

  const decrementValue = (setter, decrementAmount) => {
    Swal.fire({
      title: 'Enter PIN to Decrease Value',
      input: 'password',
      inputAttributes: {
        maxlength: 4,
        autocapitalize: 'off',
        autocorrect: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        const pin = result.value;
        if (pin === '1551') {
          setter(prev => Math.max(0, prev - decrementAmount));
          Swal.fire('Success', 'Value decreased!', 'success');
        } else {
          Swal.fire('Error', 'Incorrect PIN!', 'error');
        }
      }
    });
  };

  return (
    <div className="small-container">
      <div className="total-spent-card">
        <h2>Total Spent: GHS {totalSpent}</h2>
      </div>

      <form onSubmit={handleUpdate}>
        <h1>Edit Customer Data</h1>

        <label htmlFor="firstName">First Name</label>
        <input
          id="firstName"
          type="text"
          name="firstName"
          value={firstName}
          readOnly
        />

        <label htmlFor="date">Date</label>
        <input
          id="date"
          type="date"
          name="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
        />

        <div className="input-group">
          {[
            { label: "Video Games", value: videoGames, setValue: setVideoGames, increment: 10 },
            { label: "Socks", value: socks, setValue: setSocks, increment: 10 },
            { label: "Basketball", value: basketball, setValue: setBasketball, increment: 10 },
            { label: "ConnectBall", value: connectball, setValue: setConnectBall, increment: 10 },
            { label: "Jump It", value: jumpIt, setValue: setJumpIt, increment: 10 },
            { label: "Hammer", value: hammer, setValue: setHammer, increment: 15 },
            { label: "Hit Beans", value: hitBeans, setValue: setHitBeans, increment: 20 },
            { label: "Just Dance", value: justDance, setValue: setJustDance, increment: 20 },
            { label: "Claw Machine", value: clawMachine, setValue: setClawMachine, increment: 20 },
            { label: "Racing", value: racing, setValue: setRacing, increment: 20 },
            { label: "Aliens", value: aliens, setValue: setAliens, increment: 30 },
            { label: "VR", value: vr, setValue: setVr, increment: 30 },
            { label: "F1", value: f1, setValue: setF1, increment: 30 },
            { label: "Snowcross", value: snowcross, setValue: setSnowCross, increment: 40 },
            { label: "Karaoke", value: karaoke, setValue: setKaraoke, increment: 40 },
            { label: "Jurassic Park", value: jurassicPark, setValue: setJurassicPark, increment: 50 },
            { label: "Laser Tag", value: laserTag, setValue: setLaserTag, increment: 50 },
            { label: "Football Snooker", value: footballSnooker, setValue: setFootballSnooker, increment: 50 },
            { label: "Go Kart", value: goKart, setValue: setGoKart, increment: 50 },
          ].map(({ label, value, setValue, increment }) => (
            <div className="input-field" key={label}>
              <label htmlFor={label.toLowerCase().replace(" ", "")}>{label}</label>
              <input
                id={label.toLowerCase().replace(" ", "")}
                type="number"
                name={label.toLowerCase().replace(" ", "")}
                value={value}
                readOnly
              />
              <button type="button" onClick={() => incrementValue(setValue, increment)}>
                <FaPlus />
              </button>
              <button type="button" onClick={() => decrementValue(setValue, increment)} className="decrement-button">
                <FaMinus />
              </button>
            </div>
          ))}
        </div>

        <div className="button-group">
          <button type="submit" className="update-button">Update</button>
          <button type="button" className="cancel-button" onClick={() => setIsEditing(false)}>Cancel</button>
        </div>
      </form>

      <h3>Edit History</h3>
      <ul>
        {editHistory.map((record, index) => (
          <li key={index}>
            Edited at {record.time}, Date: {record.date}
          </li>
        ))}
      </ul>

      <style jsx>{`
        .small-container {
          position: relative;
        }
        .total-spent-card {
          position: sticky;
          top: 0;
          z-index: 100;
          margin-bottom: 20px;
          padding: 10px;
          background-color: #f0f0f0;
          border: 1px solid #ccc;
          border-radius: 5px;
          text-align: center;
        }
        .input-group {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          margin-bottom: 20px;
        }
        .input-field {
          display: flex;
          flex-direction: column;
          width: 48%;
        }
        .input-field button {
          margin-top: 5px;
        }
        .button-group {
          display: flex;
          gap: 10px;
          margin-top: 20px;
        }
        .update-button,
        .cancel-button {
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .update-button {
          background-color: #4CAF50;
          color: white;
        }
        .cancel-button {
          background-color: #f44336;
          color: white;
        }
        .decrement-button {
          background-color: red;
          color: white;
        }
        .update-button:hover {
          background-color: #45a049;
        }
        @media (max-width: 600px) {
          .button-group {
            flex-direction: column;
          }
        }
      `}</style>
    </div>
  );
};

export default Edit;
