import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { collection, setDoc, doc } from "firebase/firestore";
import { db } from '../../../src/config/firestore';
import styled from 'styled-components';

const Add = ({ employees, setEmployees, setIsAdding, getEmployees }) => {
  const [firstName, setFirstName] = useState('');
  const [videoGames, setVideoGames] = useState(0);
  const [socks, setSocks] = useState(0);
  const [goKart, setGoKart] = useState(0);
  const [hammer, setHammer] = useState(0);
  const [hitBeans, setHitBeans] = useState(0);
  const [clawMachine, setClawMachine] = useState(0);
  const [aliens, setAliens] = useState(0);
  const [jumpIt, setJumpIt] = useState(0);
  const [f1, setF1] = useState(0);
  const [connectball, setConnectBall] = useState(0);
  const [jurassicPark, setJurassicPark] = useState(0);
  const [justDance, setJustDance] = useState(0);
  const [karaoke, setKaraoke] = useState(0);
  const [snowcross, setSnowCross] = useState(0);
  const [laserTag, setLaserTag] = useState(0);
  const [footballSnooker, setFootballSnooker] = useState(0);
  const [basketball, setBasketball] = useState(0);
  const [malt, setMalt] = useState(0);
  const [racing, setRacing] = useState(0);
  const [softDrink, setSoftDrink] = useState(0);
  const [toyCars, setToyCars] = useState(0);
  const [vr, setVr] = useState(0);
  const [date, setDate] = useState('');
  const [totalSpent, setTotalSpent] = useState(0);

  const toNumber = (value) => (value === '' || isNaN(value) ? 0 : parseFloat(value));

  useEffect(() => {
    const total = (toNumber(videoGames) * 10) + toNumber(goKart * 50) + toNumber(hammer * 15) + toNumber(basketball * 10) + toNumber(connectball * 10) + toNumber(socks*10)+
      toNumber(hitBeans * 20) + toNumber(jumpIt * 10) + toNumber(jurassicPark * 50) + toNumber(clawMachine * 20) + toNumber(f1 * 30) + toNumber(snowcross * 40) +
      toNumber(aliens * 20) + toNumber(justDance * 20) + toNumber(karaoke * 40) + toNumber(laserTag * 50) +
      toNumber(footballSnooker * 50) + toNumber(malt * 15) + toNumber(racing * 20) + toNumber(softDrink * 10) +
      toNumber(toyCars * 20) + toNumber(vr * 30);

    setTotalSpent(total);
  }, [videoGames, goKart, hammer, snowcross, hitBeans, connectball, jumpIt, jurassicPark, aliens,socks, basketball, f1, justDance, karaoke, laserTag, footballSnooker, malt, racing, softDrink, toyCars, vr, clawMachine]);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (!firstName || !date) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'All required fields must be filled.',
        showConfirmButton: true,
      });
    }

    const newEmployee = {
      firstName,
      videoGames: toNumber(videoGames) * 10,
      basketball: toNumber(basketball) * 10,
      jumpIt: toNumber(jumpIt) * 10,
      socks: toNumber(socks) * 10,
      connectball: toNumber(connectball) * 10,
      hammer: toNumber(hammer) * 15,
      aliens: toNumber(aliens) * 20,
      goKart: toNumber(goKart) * 50,
      hitBeans: toNumber(hitBeans) * 20,
      clawMachine: toNumber(clawMachine) * 20,
      jurassicPark: toNumber(jurassicPark) * 50,
      justDance: toNumber(justDance) * 20,
      karaoke: toNumber(karaoke) * 40,
      snowcross: toNumber(snowcross) * 40,
      laserTag: toNumber(laserTag) * 50,
      footballSnooker: toNumber(footballSnooker) * 50,
      malt: toNumber(malt) * 15,
      racing: toNumber(racing) * 20,
      softDrink: toNumber(softDrink) * 10,
      toyCars: toNumber(toyCars) * 20,
      vr: toNumber(vr) * 30,
      totalSpent,
      date,
    };

    employees.push(newEmployee);
    const customId = `gameit-${Math.random().toString(36).substr(2, 3)}`;

    try {
      await setDoc(doc(collection(db, "employees"), customId), { ...newEmployee });
      console.log("Document written with custom ID: ", customId);
    } catch (error) {
      console.log(error);
    }

    setEmployees(employees);
    setIsAdding(false);
    getEmployees();

    Swal.fire({
      icon: 'success',
      title: 'Added!',
      text: `${firstName}'s data has been added and ID created is ${customId}.`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const currentTime = new Date().toLocaleTimeString();
    const fullDate = `${selectedDate} ${currentTime}`;
    setDate(fullDate);
  };

  return (
    <AddContainer>
      <div className="cancel-button">
        <button onClick={() => setIsAdding(false)}>Cancel</button>
      </div>

      <StickyTotalSpentCard>
        Total Amount: <span className="amount">GHS {totalSpent.toFixed(2)}</span>
      </StickyTotalSpentCard>

      <form onSubmit={handleAdd}>
        <h1>Add Customer Data</h1>
        <label htmlFor="firstName">First Name</label>
        <input
          id="firstName"
          type="text"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />

        <GameInputsContainer>
          {[
            { label: 'Video Games', value: videoGames, setValue: setVideoGames },
            { label: 'Basketball', value: basketball, setValue: setBasketball },
            { label: 'Connect Ball', value: connectball, setValue: setConnectBall },
            { label: 'Socks', value:socks, setValue: setSocks },
            { label: 'Jump It', value: jumpIt, setValue: setJumpIt },
            { label: 'Hammer', value: hammer, setValue: setHammer },
            { label: 'Just Dance', value: justDance, setValue: setJustDance },
            { label: 'Aliens', value: aliens, setValue: setAliens },
            { label: 'Hit Beans', value: hitBeans, setValue: setHitBeans },
            { label: 'Claw Machine', value: clawMachine, setValue: setClawMachine },
            { label: 'Racing', value: racing, setValue: setRacing },
            { label: 'VR', value: vr, setValue: setVr },
            { label: 'F1', value: f1, setValue: setF1 },
            { label: 'Snowcross Motor', value: snowcross, setValue: setSnowCross },
            { label: 'Karaoke', value: karaoke, setValue: setKaraoke },
            { label: 'Jurassic Park', value: jurassicPark, setValue: setJurassicPark },
            { label: 'Laser Tag', value: laserTag, setValue: setLaserTag },
            { label: 'Football Snooker', value: footballSnooker, setValue: setFootballSnooker },
            { label: 'Go Kart (GHS)', value: goKart, setValue: setGoKart },
            { label: 'Malt', value: malt, setValue: setMalt },
            { label: 'Soft Drink', value: softDrink, setValue: setSoftDrink },
            { label: 'Toy Cars', value: toyCars, setValue: setToyCars },
          ].map(({ label, value, setValue }) => (
            <div className="grid-item" key={label}>
              <label htmlFor={label}>{label}</label>
              <input
                id={label}
                type="number"
                value={value || 0}
                onChange={e => setValue(toNumber(e.target.value))}
              />
            </div>
          ))}
        </GameInputsContainer>

        <label htmlFor="date">Date</label>
        <input
          id="date"
          type="date"
          onChange={handleDateChange}
        />

        <button type="submit">Add</button>
      </form>
    </AddContainer>
  );
};

const AddContainer = styled.div`
  /* Container styles */

  label {
    font-size: 0.8rem; /* Reduced font size */
    margin-top: 5px; /* Less margin to fit more labels in line */
    text-align: center; /* Center align labels for a uniform look */
  }

  button {
    margin-top: 20px;
    background-color: #28a745; /* Green */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
      background-color: #218838;
    }
  }

  .cancel-button {
    margin-bottom: 10px;

    button {
      background-color: #dc3545; /* Red */
      margin-top: 0;
    }
  }
`;

const GameInputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 10px;
  margin-top: 20px;

  .grid-item {
    display: flex;
    flex-direction: column;
    width: calc(20% - 10px); /* Adjust width for better fitting */
  }

  @media (min-width: 768px) {
    .grid-item {
      width: calc(15% - 10px); /* More columns on larger screens */
    }
  }

  @media (min-width: 1024px) {
    .grid-item {
      width: calc(12% - 10px); /* More columns on even larger screens */
    }
  }

  @media (min-width: 1200px) {
    .grid-item {
      width: calc(10% - 10px); /* Adjust for maximum columns */
    }
  }
`;

const StickyTotalSpentCard = styled.div`
  position: sticky;
  top: 0;
  background-color: #f8f9fa; /* Light background */
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Border for better visibility */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .amount {
    font-weight: bold;
  }
`;

export default Add;
